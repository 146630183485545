import React from 'react';
import { Col, Row } from 'antd';
import styled from '@emotion/styled';

import { mediaQueries, color } from '../variables';
import coverIcon from '../../images/marginalia-payment-processed.svg';
import * as S from '../shared/components/styles';
import { SectionWrapper, ContentRow, SectionIcon } from '../businesses/styles';

const ContentWrapper = styled.div`
  text-align: left;
  ${mediaQueries.lg} {
    justify-content: center;
  }
  ${mediaQueries.md} {
    display: flex;
    flex-direction: column;
    background-color: ${color.white};
    border-radius: 1rem;
    width: 90vw;
    text-align: center;
    margin: 0 auto;
    box-shadow: 0 8px 25px 0 rgba(189, 207, 217, 0.42);
  }
  ${mediaQueries.sm} {
    margin: 0;
    padding: 2rem;
  }
`;

const ModernHealthCoversSection = () => (
  <SectionWrapper>
    <ContentRow>
      <ContentWrapper>
        <Col sm={24} md={12} lg={10} xxl={10}>
          <SectionIcon extraMargin centered src={coverIcon} />
        </Col>
        <Col sm={24} md={12} lg={{ span: 10, offset: 3 }} xxl={10}>
          <S.CardHeading css={{ maxWidth: '32rem' }}>Modern Health covers it.</S.CardHeading>
          <S.SectionDescription small>
            Modern Health pays providers directly so employees don’t have to worry about it.
          </S.SectionDescription>
        </Col>
      </ContentWrapper>
    </ContentRow>
  </SectionWrapper>
);

export default ModernHealthCoversSection;
