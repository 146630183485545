import React from 'react';
import styled from '@emotion/styled';
import { Col } from 'antd';

import { mediaQueries, color } from '../../variables';

const CardWrapper = styled(Col)(({ vertical, square, horizontal }) => ({
  display: 'flex',
  margin: '1rem',
  justifyContent: 'center',
  [mediaQueries.xl]: {
    ':nth-of-type(odd)': {
      bottom: 0,
    },
    ':nth-of-type(2)': {
      top: 0,
    },
  },
  [mediaQueries.sm]: {
    width: '100vw',
    display: 'flex',
    justifyContent: 'center',
    margin: vertical ? '0' : '1rem',
  },
}));

const CardContainer = styled.div(({ vertical, noBorder, smallContent }) => ({
  borderRadius: '0.5rem',
  display: 'flex',
  flexDirection: 'column',
  padding: smallContent ? '2rem 1rem 3rem' : '2rem 2rem',
  textAlign: 'center',
  backgroundColor: color.white,
  width: '17rem',
  minHeight: '17rem',
  '&:hover': {
  },
  [mediaQueries.sm]: {
    flexDirection: vertical ? 'column' : 'row',
    paddingBottom: '0rem',
    paddingTop: '0rem',
    minWidth: vertical ? '19rem' : '90vw',
    border: 'none',
    minHeight: vertical ? '24rem' : '6rem',
  },
}));

const CardTitle = styled.h3(({ vertical, allignContentLeft, smallContent }) => ({
  textAlign: allignContentLeft && 'left',
  paddingLeft: allignContentLeft && '2rem',
  width: '100%',
  margin: '0',
  color: vertical ? color.bahamaBlue : color.bahamaBlue,
  paddingTop: '1rem',
  paddingBottom: vertical ? '1rem' : 0,
  fontSize: smallContent ? '14px' : '20px',
  [mediaQueries.sm]: {
    fontSize: '18px',
  },
}));

const CardContent = styled.p(({ vertical, smallContent, smallMargin, allignContentLeft }) => ({
  textAlign: allignContentLeft && 'left',
  margin: smallMargin ? '0 1rem' : '1rem 2rem',
  color: color.manatee,
  fontSize: smallContent ? '14px' : '18px',
  fontSize: allignContentLeft && '14px',
  [mediaQueries.sm]: {
    fontSize: vertical ? '15px' : '14px',
    padding: '0',
    margin: '0',
  },
}));

const CardIcon = styled.img(({ iconUrl, smallIcon, allignContentLeft }) => ({
  margin: allignContentLeft ? '0 1rem' : '0 auto',
  display: 'block',
  backgroundImage: `url('${iconUrl}')`,
  width: smallIcon ? '5rem' : '10rem',
  height: smallIcon ? '5rem' : '10rem',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  [mediaQueries.sm]: {
    margin: smallIcon ? '1.5rem 0 0 1rem' : '0 0 0 2rem',
    width: smallIcon ? '3rem' : '16rem',
    height: smallIcon ? '3rem' : '13rem',
  },
}));

const CardContentWrapper = styled.div(({ vertical }) => ({
  [mediaQueries.sm]: {
    textAlign: vertical ? 'center' : 'left',
    padding: vertical ? '1rem 1' : '0 1rem 1rem 2rem',
    fontSize: '16px',
  },
}));

const Card = ({
  title,
  icon,
  description,
  vertical,
  smallIcon,
  noBorder,
  smallContent,
  horizontal,
  square,
  smallMargin,
  allignContentLeft,
}) => (
  <CardWrapper
    horizontal={horizontal}
    vertical={vertical}
    square={square}
    sm={8}
    md={7}
    lg={square ? 8 : 5}
    xl={square ? 10 : 5}
  >
    <CardContainer smallContent={smallContent} noBorder={noBorder} vertical={vertical}>
      <CardIcon allignContentLeft={allignContentLeft} smallIcon={smallIcon} src={icon} />
      <CardContentWrapper vertical={vertical}>
        <CardTitle
          allignContentLeft={allignContentLeft}
          smallContent={smallContent}
          vertical={vertical}
        >
          {title}
        </CardTitle>
        <CardContent
          allignContentLeft={allignContentLeft}
          smallMargin={smallMargin}
          smallContent={smallContent}
          vertical={vertical}
        >
          {description}
        </CardContent>
      </CardContentWrapper>
    </CardContainer>
  </CardWrapper>
);

export default Card;
