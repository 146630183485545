import React from 'react';

import Layout from '../components/layout';
import Navigation from '../components/header/Navigation';
import JoinSection from '../components/providers/JoinSection';
import PartneringSection from '../components/providers/PartneringSection';
import HowModernSection from '../components/providers/HowModernSection';
import BusinessesPartnerSection from '../components/providers/BusinessesPartnerSection';
import EmployeesJoinSection from '../components/providers/EmployeesJoinSection';
import ModernHealthMatchesSection from '../components/providers/ModernHealthMatchesSection';
import ModernHealthCoversSection from '../components/providers/ModernHealthCoversSection';
import JoinTeamSection from '../components/providers/JoinTeamSection';
import FooterWrapper from '../components/index/FooterWrapper';

const ForProvidersPage = () => (
  <Layout>
    <Navigation />
    <JoinSection />
    <PartneringSection />
    <HowModernSection />
    <BusinessesPartnerSection />
    <EmployeesJoinSection />
    <ModernHealthMatchesSection />
    <ModernHealthCoversSection />
    <FooterWrapper children={<JoinTeamSection email={'mailto:providers@joinmodernhealth.com'}/>} />
  </Layout>
);

export default ForProvidersPage;
