import styled from '@emotion/styled';
import { Row } from 'antd';

import { mediaQueries, color } from '../variables';

export const ContentWrapper = styled.div`
  margin-left: 2rem;
  text-align: left;
  ${mediaQueries.lg} {
    justify-content: center;
  }
  ${mediaQueries.md} {
    display: flex;
    flex-direction: column-reverse;
    background-color: ${color.white};
    border-radius: 1rem;
    width: 90vw;
    text-align: center;
    margin: 0 auto;
    box-shadow: 0 8px 25px 0 rgba(189, 207, 217, 0.42);
  }
  ${mediaQueries.sm} {
    margin: 1rem auto;
    padding: 2rem;
  }
`;

export const SectionIcon = styled.img(({ centered, extraMargin }) => ({
  marginLeft: extraMargin && '2rem',
  bottom: '7rem',
  display: 'block',
  position: 'relative',
  width: '29rem',
  height: '29rem',
  backgroundRepeat: 'no-repeat',
  [mediaQueries.xl]: {
    bottom: '3rem',
    width: '20rem',
    height: '23rem',
  },
  [mediaQueries.lg]: {
    bottom: centered ? '4rem' : '5rem',
  },
  [mediaQueries.md]: {
    display: 'flex',
    margin: '0 auto',
    bottom: 0,
    height: '16rem',
  },
  [mediaQueries.sm]: {
    width: '17rem',
  },
}));

export const SectionWrapper = styled(Row)`
  padding: 1rem 9rem 6rem 20rem;
  background-color: ${color.white};
  background-size: cover;
  ${mediaQueries.xxxl} {
    padding: 1rem 9rem 1rem 14rem;
  }
  ${mediaQueries.xxl} {
    padding: 5rem 9rem 6rem;
  }
  ${mediaQueries.xl} {
    padding: 5rem 5rem 1rem;
  }
  ${mediaQueries.lg} {
    padding: 5rem 2rem;
  }
  ${mediaQueries.md} {
    padding: 5rem 2rem 2rem;
  }
  ${mediaQueries.sm} {
    padding: 1rem;
  }
`;

export const ContentRow = styled(Row)`
  display: block;
  width: 100%;
  margin: 0 auto;
`;
