import React from 'react';
import styled from '@emotion/styled';
import { Button } from 'antd';

import { mediaQueries } from '../../variables';
import InboundProviderFormModal from './InboundProviderFormModal';
import { LabelButtonWrapper } from './styles';

const InboundProviderBtn = styled(Button)`
  && {
    width: 20rem;
    height: 3rem;
    margin-top: 0.75rem;
    margin-right: 1rem;
    border-radius: 2rem;
    color: white;
    background-color: #4c7de0;
    font-size: 1rem;
    border-color: #4c7de0;
    outline: none;
    box-shadow: 0 6px 12px 0 #ebeaf4;
    &:hover {
      background-color: #4470c9;
      color: white;
      border-color: #4470c9;
    }
    ${mediaQueries.sm} {
      width: 20rem;
      margin: 1rem auto;
    }
    ${mediaQueries.xs} {
      width: 18rem;
    }
  }
`;

class InboundProviderFormModalWidget extends React.Component {
  render() {
    return (
      <>
        <InboundProviderBtn>
          <a href="https://www.fountain.com/modern-health/apply/modern-health">
            <LabelButtonWrapper>Apply to become a Provider</LabelButtonWrapper>
          </a>
        </InboundProviderBtn>
      </>
    );
  }
}

export default InboundProviderFormModalWidget;

