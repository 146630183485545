import React from 'react';
import styled from '@emotion/styled';
import { Row, Col, Button } from 'antd';

import womenIcon from '../../images/illu-mountain.svg';
import { mediaQueries, color } from '../variables';
import * as Styles from '../shared/components/styles';
import InboundProviderButtonWidget  from '../shared/components/InboundProviderButtonWidget';

const SectionWrapper = styled(Row)`
  padding: 15rem 16rem 0rem;
  background-color: ${color.aliceBlueLight};
  background-size: cover;
  ${mediaQueries.xxxl} {
    padding: 15rem 10rem 0rem;
  }
  ${mediaQueries.xl} {
    padding: 10rem 5rem 3rem;
  }
  ${mediaQueries.lg} {
    padding: 10rem 2rem;
  }
  ${mediaQueries.md} {
    padding: 10rem 2rem 2rem;
  }
  ${mediaQueries.sm} {
    padding: 7rem 2rem 0;
  }
`;

const ContentRow = styled(Row)`
  display: block;
  width: 100%;
  margin: 0 auto;
`;

const ContentColumn = styled(Col)`
  text-align: left;
`;

const SectionIcon = styled.i(({ iconUrl }) => ({
  display: 'block',
  backgroundImage: `url('${iconUrl}')`,
  width: '37rem',
  height: '28rem',
  margin: '0 auto',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundPosition: 'center',
  marginTop: '8rem',
  [mediaQueries.xxl]: {
    width: '30rem',
  },
  [mediaQueries.lg]: {
    width: '25rem',
    marginTop: '0',
  },
  [mediaQueries.md]: {
    width: '20rem',
    height: '22rem',
  },
  [mediaQueries.sm]: {
    marginTop: '2rem',
    width: '17rem',
    height: '13rem',
  },
}));

const StyledButton = styled(Button)`
  && {
    width: 11rem;
    height: 3rem;
    border-radius: 2rem;
    color: white;
    background-image: linear-gradient(to top, ${color.summerSkyMediumDark}, ${color.turquoiseBlue});
    font-size: 16px;
    border: none;
    outline: none;
    &:hover {
      background-image: linear-gradient(to top, ${color.summerSkyDark}, ${color.turquoiseDark});
      color: ${color.white};
      border-color: ${color.mediumTurquoiseDark};
    }
    ${mediaQueries.md} {
      margin-top: 1rem;
      width: 17rem;
    }
  }
`;

const JoinSection = () => (
  <SectionWrapper>
    <ContentRow>
      <ContentColumn md={24} lg={12} xl={14}>
        <Styles.SectionWelcomeHeading css={{ maxWidth: '44rem' }}>
          Join us on our mission to make mental healthcare more accessible
        </Styles.SectionWelcomeHeading>
        <Styles.SectionDescription
          semiTranspatentOpacity
          css={{
            maxWidth: '40rem',
            [mediaQueries.sm]: {
              paddingBottom: '1rem',
            },
          }}
        >
          Modern Health is a comprehensive approach to mental well-being that takes care of people
          holistically. As a Modern Health coach or therapist, you will get to work with motivated
          clients, be part of a vibrant community, and do what you love.
        </Styles.SectionDescription>
        <Styles.ButtonsWrapper css={{ [mediaQueries.sm]: { flexDirection: 'row' } }}>
          <InboundProviderButtonWidget />
        </Styles.ButtonsWrapper>
      </ContentColumn>
      <ContentColumn md={24} lg={12} xl={10}>
        <SectionIcon iconUrl={womenIcon} />
      </ContentColumn>
    </ContentRow>
  </SectionWrapper>
);

export default JoinSection;
