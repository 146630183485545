import React from 'react';
import { Select } from 'antd';

import modalIcon from '../../../images/illu-placeholder.svg';
import tadaIcon from '../../../images/tada-20.svg';
import { handleInboundProviderContactFormSubmit } from '../../../services/RequestFormService';
import * as Styles from './styles';

const initialState = {
  firstName: '',
  lastName: '',
  email: '',
  providerType: '',
  country: '',
  city: '',
  postalCode: '',
  showThanks: false,
};

class InboundProviderContactFormModal extends React.Component {
  state = initialState;

  handleInputChange = event => this.setState({ [event.target.name]: event.target.value });

  handleSelectChange = providerType => {
    this.setState({ providerType });
  };

  toggleThanksModal = () => this.setState({ showThanks: !this.state.showThanks });

  handleSubmit = event => {
    event.preventDefault();
    handleInboundProviderContactFormSubmit(this.state)
      .then(this.toggleThanksModal)
      .catch(alert);
  };

  handleModalClose = () => {
    this.props.onCancel();
    this.setState(initialState);
  };

  render() {
    const { onCancel, isVisible } = this.props;

    return (
      <Styles.StyledModal footer={false} closable={true} visible={isVisible} onCancel={onCancel}>
        {this.state.showThanks ? (
          <Styles.ContentRow>
            <Styles.ContentWrapper>
              <Styles.ModalIcon small iconUrl={tadaIcon} />
              <Styles.ModalTitle>Thank you!</Styles.ModalTitle>
              <Styles.ModalDescription center>
                Member of our team will reach out to you soon.
              </Styles.ModalDescription>
              <Styles.ModalDescription center>
                We will send an email to:
                <Styles.UserEmail> {this.state.email}</Styles.UserEmail>
              </Styles.ModalDescription>
              <Styles.ModalButton small onClick={this.handleModalClose}>
                Back to Modern Health
              </Styles.ModalButton>
            </Styles.ContentWrapper>
          </Styles.ContentRow>
        ) : (
          <Styles.ContentRow>
            <Styles.ContentColumn withicon={1} lg={14}>
              <Styles.ModalIcon iconUrl={modalIcon} />
              <Styles.ModalTitle>We'd love to hear from you!</Styles.ModalTitle>
              <Styles.ModalDescription>
                Fill out this form, and we'll have a member of our team reach out to you right away.
              </Styles.ModalDescription>
            </Styles.ContentColumn>
            <Styles.ContentColumn md={24} lg={10}>
              <Styles.ModalTitle>Provider Info</Styles.ModalTitle>
              <form onSubmit={this.handleSubmit}>
                <Styles.InputWrapper>
                  <Styles.InputLabel>First Name*</Styles.InputLabel>
                  <Styles.StyledInput
                    type="text"
                    name="firstName"
                    placeholder="Jim"
                    onChange={this.handleInputChange}
                    required
                  />
                </Styles.InputWrapper>
                <Styles.InputWrapper>
                  <Styles.InputLabel>Last Name*</Styles.InputLabel>
                  <Styles.StyledInput
                    type="text"
                    name="lastName"
                    placeholder="Rohn"
                    onChange={this.handleInputChange}
                    required
                  />
                </Styles.InputWrapper>
                <Styles.InputWrapper>
                  <Styles.InputLabel>Email*</Styles.InputLabel>
                  <Styles.StyledInput
                    type="string"
                    name="email"
                    placeholder="jim.rohn@company.com"
                    onChange={this.handleInputChange}
                    required
                  />
                </Styles.InputWrapper>
                <Styles.InputWrapper>
                  <Styles.InputLabel>Provider Type*</Styles.InputLabel>
                  <Styles.StyledSelect
                    defaultValue="Please select"
                    onChange={this.handleSelectChange}
                    name="providerType"
                    required
                  >
                    <Select.Option value="Coach">Coach</Select.Option>
                    <Select.Option value="Therapist">Therapist</Select.Option>
                  </Styles.StyledSelect>
                </Styles.InputWrapper>
                <Styles.InputWrapper>
                  <Styles.InputLabel>Country*</Styles.InputLabel>
                  <Styles.StyledInput
                    type="text"
                    name="country"
                    placeholder="United States"
                    onChange={this.handleInputChange}
                    required
                  />
                </Styles.InputWrapper>
                <Styles.InputWrapper>
                  <Styles.InputLabel>City*</Styles.InputLabel>
                  <Styles.StyledInput
                    type="text"
                    name="city"
                    placeholder="San Franisco"
                    onChange={this.handleInputChange}
                    required
                  />
                </Styles.InputWrapper>
                <Styles.InputWrapper>
                  <Styles.InputLabel>State/Province</Styles.InputLabel>
                  <Styles.StyledInput
                    type="text"
                    name="postalCode"
                    placeholder="California"
                    onChange={this.handleInputChange}
                  />
                </Styles.InputWrapper>
                <Styles.ButtonWrapper>
                  <Styles.ModalButton>Submit</Styles.ModalButton>
                </Styles.ButtonWrapper>
              </form>
            </Styles.ContentColumn>
          </Styles.ContentRow>
        )}
      </Styles.StyledModal>
    );
  }
}

export default InboundProviderContactFormModal;
