import React from 'react';
import styled from '@emotion/styled';
import { Col, Row, Button } from 'antd';

import { mediaQueries, color } from '../variables';
import * as Styles from '../shared/components/styles';
import InboundProviderButtonWidget  from '../shared/components/InboundProviderButtonWidget';

const ContentRow = styled(Row)`
  width: 100%;
  padding: 2rem 5rem;
  display: block;
  text-align: center;
  ${mediaQueries.xl} {
    padding: 0;
  }
`;

const SectionHeading = styled.h2`
  color: ${color.white};
  margin: '0 0 1rem';
  font-size: 40px;
  font-weight: 500;
  padding-bottom: 1rem;
  ${mediaQueries.sm} {
    font-size: 28px;
  }
`;

const SectionWrapper = styled(Col)`
  padding: 11rem 1rem 2rem;
  width: 100%;
  background-color: transparent;
  background-size: cover;
  ${mediaQueries.sm} {
    padding: 2rem 1rem 1rem;
  }
`;

const HeaderWrapper = styled.div`
  display: block;
  justify-content: center;
`;

const StyledButton = styled(Button)`
  && {
    width: 11rem;
    height: 3rem;
    border-radius: 2rem;
    color: white;
    background-image: linear-gradient(to top, ${color.summerSky}, ${color.turquoise});
    font-size: 16px;
    border: none;
    outline: none;
    &:hover {
      background-image: linear-gradient(to top, ${color.summerSkyDark}, ${color.turquoiseDark});
      color: ${color.white};
      border-color: ${color.mediumTurquoiseDark};
    }
  }
`;

const JoinTeamSection = ({email}) => (
  <SectionWrapper>
    <ContentRow>
      <HeaderWrapper>
        <SectionHeading>Join the Modern Health Team</SectionHeading>
        <Styles.ButtonsWrapper>
          <InboundProviderButtonWidget />
        </Styles.ButtonsWrapper>
      </HeaderWrapper>
    </ContentRow>
  </SectionWrapper>
);

export default JoinTeamSection;
