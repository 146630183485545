import React from 'react';
import styled from '@emotion/styled';
import { Col, Row } from 'antd';

import matchesIcon from '../../images/marginalia-unsubscribed.svg';
import { mediaQueries, color } from '../variables';
import * as S from '../shared/components/styles';
import { SectionIcon, ContentWrapper, ContentRow } from '../businesses/styles';

const SectionWrapper = styled(Row)`
  padding: 1rem 9rem 6rem 20rem;
  background-color: ${color.white};
  background-size: cover;
  ${mediaQueries.xxxl} {
    padding: 1rem 9rem 1rem 14rem;
  }
  ${mediaQueries.xxl} {
    padding: 5rem 9rem 6rem;
  }
  ${mediaQueries.xl} {
    padding: 5rem 5rem 1rem;
  }
  ${mediaQueries.lg} {
    padding: 5rem 2rem;
  }
  ${mediaQueries.md} {
    padding: 5rem 2rem 2rem;
  }
  ${mediaQueries.sm} {
    padding: 1rem;
  }
`;

const ModernHealthMatchesSection = () => (
  <SectionWrapper>
    <ContentRow>
      <ContentWrapper>
        <Col sm={24} md={12} lg={10} xxl={10}>
          <S.CardHeading css={{ maxWidth: '32rem' }}>
            Modern Health matches employees to the right care at the right time.
          </S.CardHeading>
          <S.SectionDescription small>
            Employees are matched to professional support based on their needs and preferences.
          </S.SectionDescription>
        </Col>
        <Col sm={24} md={12} lg={{ span: 10, offset: 2 }} xxl={10}>
          <SectionIcon centered src={matchesIcon} />
        </Col>
      </ContentWrapper>
    </ContentRow>
  </SectionWrapper>
);

export default ModernHealthMatchesSection;
