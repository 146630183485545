import React from 'react';
import styled from '@emotion/styled';
import { Col, Row } from 'antd';

import workIcon from '../../images/icon-work-with-clients.svg';
import createIcon from '../../images/icon-create-and-share.svg';
import provideIcon from '../../images/icon-provide-feedback.svg';
import collaborateIcon from '../../images/icon-collaborate-and-learn.svg';
import { mediaQueries, color } from '../variables';
import Card from '../shared/components/Card';
import { SectionHeading } from '../shared/components/styles';

const cards = [
  {
    icon: workIcon,
    title: 'Work with your ideal clients',
    description: 'on the issues that matter to them',
  },
  {
    icon: collaborateIcon,
    title: 'Collaborate and learn',
    description: 'from a team of coaches and therapists',
  },
  {
    icon: provideIcon,
    title: 'Provide meaningful feedback',
    description: 'on program improvement',
  },
  {
    icon: createIcon,
    title: 'Create and share',
    description: 'evidence-based content',
  },
];

const ContentRow = styled(Row)`
  width: 100%;
  padding: 2rem 5rem;
  display: block;
  text-align: center;
  ${mediaQueries.xl} {
    padding: 0;
  }
`;

const CardsWrapper = styled(Row)`
  padding: 2rem 10rem;
  display: flex;
  justify-content: center;
  ${mediaQueries.xxl} {
    padding: 2rem 4rem;
  }
  ${mediaQueries.xl} {
    padding: 0 5rem;
  }
  ${mediaQueries.xl} {
    padding: 0;
  }
`;

const SectionWrapper = styled(Col)`
  padding: 2rem 0;
  justify-content: center;
  width: 100%;
  background-color: ${color.white};
  background-size: cover;
  ${mediaQueries.sm} {
    padding: 1rem 3rem;
  }
`;

const PartneringSection = () => (
  <SectionWrapper>
    <ContentRow>
      <SectionHeading>What you get by partnering with Modern Health</SectionHeading>
    </ContentRow>
    <CardsWrapper type="flex">
      {cards.map(card => (
        <Card
          smallMargin
          noBorder
          smallIcon
          smallContent
          key={card.icon}
          icon={card.icon}
          title={card.title}
          description={card.description}
        />
      ))}
    </CardsWrapper>
  </SectionWrapper>
);

export default PartneringSection;
