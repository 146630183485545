import React from 'react';
import styled from '@emotion/styled';
import { Row } from 'antd';

import { mediaQueries, color } from '../variables';
import * as S from '../shared/components/styles';

const SectionWrapper = styled(Row)`
  padding: 10rem 9rem 6rem;
  background-color: ${color.white};
  background-size: cover;

  ${mediaQueries.xl} {
    padding: 5rem 5rem;
  }
  ${mediaQueries.lg} {
    padding: 5rem 2rem;
  }
  ${mediaQueries.md} {
    padding: 5rem 2rem 2rem;
  }
  ${mediaQueries.sm} {
    padding: 5rem 2rem 0;
  }
`;

const ContentRow = styled(Row)`
  width: 100%;
  margin: 0 auto;

  text-align: center;
`;

const HowModernSection = () => (
  <SectionWrapper>
    <ContentRow>
      <S.SectionHeading>How Modern Health works</S.SectionHeading>
    </ContentRow>
  </SectionWrapper>
);

export default HowModernSection;
